import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import ContainerStyle from "../styles/ContainerStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Zoom, Thumbs } from "swiper";
import ProductBreadcrumbs from "../components/ProductBreadcrumbs";
import { px } from "../utils";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/zoom";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import NavigateSlider from "../components/NavigateSlider";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import BCContainer from "../styles/BCContainer";
import Seo from "../components/Seo";

const ProductStyle = styled.section`
  h1 {
    margin: 0 0 ${px(2)} 0;
  }
`;

const GridProductStyle = styled.div`
  margin: 20px 0;
  gap: 40px;
  .slider-wrapper {
    background-color: ${({ theme: { palette } }) => palette.primary[100]};
    padding: 20px;
    box-sizing: content-box;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-bottom: 20px !important;
    .slides-wrapper {
      margin-bottom: 10px;
      [data-gatsby-image-wrapper] {
        height: 100%;
      }
    }
    .swiper,
    [data-gatsby-image-wrapper] {
      height: 100%;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
    .thumbs-wrapper {
      position: relative;
      .custom-swiper-next-nav,
      .custom-swiper-prev-nav {
        cursor: pointer;
        position: absolute;
        z-index: 9;
        top: 50%;
        transform: translateY(-50%);
        background-color: ${({ theme: { palette } }) => palette.primary.main};
        svg {
          width: 30px;
          height: 30px;
          path {
            fill: ${({ theme: { palette } }) => palette.primary[100]};
          }
        }
      }
      .custom-swiper-next-nav {
        right: 0;
        svg {
          transform: rotate(90deg);
        }
      }
      .custom-swiper-prev-nav {
        left: 0;
        svg {
          transform: rotate(90deg);
        }
      }
      .swiper-slide-thumb-active {
        img {
          border: 1px solid black;
        }
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    display: flex;
    height: 800px;
    .slider-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .slides-wrapper {
        width: 500px;
        margin-bottom: 0;
        [data-gatsby-image-wrapper] {
          height: 580px !important;
        }
      }
    }
    .thumbs-wrapper {
      height: 100%;
      .swiper-wrapper {
        width: 130px;
        flex-direction: row;
        display: flex;
        [data-gatsby-image-wrapper] {
          height: 130px;
          display: flex;
          flex-direction: row;
        }
      }
    }
    .product-description {
      margin: 50px 0 0px 0;
      display: flex;
      flex-direction: column;
      li {
        align-items: baseline;
        display: grid;
        grid-template-columns: 150px 1fr;
        gap: 20px;
        h3 {
          text-transform: capitalize;
        }
      }
      p {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .product-description,
    .product-license {
      li {
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr !important;
      }
    }
    .other-sizes {
      grid-template-columns: 1fr !important;
      grid-template-rows: 1fr !important;
    }
  }
`;

const ProductDetails = styled.div`
  h2 {
    margin: 10px 0 20px 0;
    text-transform: capitalize;
  }
  p {
    margin: 20px 0;
  }
  .product-divider {
    width: 100%;
    height: 2px;
    background-color: ${({ theme: { palette } }) => palette.primary[200]};
    margin: 20px 0;
  }
  .other-sizes {
    h2 {
      text-transform: capitalize;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      gap: 15px;
      li {
        text-transform: capitalize;
        padding: ${px(1)} ${px(7)};
        border-radius: 200px;
        background-color: ${({ theme: { palette } }) => palette.primary[200]};
        color: ${({ theme: { palette } }) => palette.primary[500]};
      }
    }
  }
  .product-license {
    .info-list {
      display: flex;
      gap: 20px;
      margin-left: 15px;
      flex-wrap: wrap;
      margin-left: 0;
      .info {
        margin-left: 20px;
      }
      li {
        list-style-type: disc !important;
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    .other-sizes {
      align-items: baseline;
      display: grid;
      grid-template-columns: 150px 1fr;
      gap: 20px;
    }
    .product-license {
      & > li {
        align-items: baseline;
        display: grid;
        grid-template-columns: 150px 1fr;
        .info-list {
          flex-direction: row;
          margin-left: 15px;
        }
      }
    }
  }
  .notice-message {
    margin-top: 20px;
    color: ${({ theme: { palette } }) => palette.secondary.main};
  }
`;

const RelatedProducts = styled.div`
  margin: 20px 0 50px 0;
  position: relative;
  .swiper {
    padding: 0 40px;
  }
  .heading {
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      background-color: ${({ theme: { palette } }) => palette.primary[200]};
      position: absolute;
      left: 0;
      height: 3px;
      width: 100%;
      top: 30%;
      transform: translateY(-50%);
    }
  }
  h2 {
    background-color: white;
    padding: 0 10px 0 0;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
  }

  .slide-content {
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    margin: 5px;
  }
  .swiper-slide {
    h4 {
      text-align: center;
      line-height: 1.5;
      margin: 20px 0 0 0;
      color: ${({ theme: { palette } }) => palette.primary.main};
    }
    .gatsby-image-wrapper {
      height: 100%;
      object-fit: cover;
    }
  }

  .custom-swiper-next-nav,
  .custom-swiper-prev-nav {
    cursor: pointer;
    position: absolute;
    z-index: 9;
    top: 55%;
    transform: translateY(-50%);
    background-color: ${({ theme: { palette } }) => palette.primary.main};
    svg {
      width: 30px;
      height: 30px;
      transform: rotate(90deg);
      path {
        fill: ${({ theme: { palette } }) => palette.primary[100]};
      }
    }
  }
  .custom-swiper-prev-nav {
    left: 0;
  }
  .custom-swiper-next-nav {
    right: 0;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: ${({ theme: { palette } }) => palette.primary.main};
  }
`;

export default function SingleProductPage({
  data: {
    strapiProduct: {
      name,
      productImages,
      category,
      productDescription,
      product_licenses,
      product_sizes,
      products,
      notice,
      metaTitle,
      metaDescription,
      keywords,
    },
  },
}) {
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const swiperRef = React.useRef(null);
  const secondSwiperRef = React.useRef(null);
  const SLIDES_PER_VIEW_THUMBS = 4;
  const isBrowser = typeof window !== "undefined";

  return (
    <>
      <Seo
        seo={{
          title: name,
          metaTitle: metaTitle || name,
          metaDescription: metaDescription
            ? metaDescription
            : productDescription?.length > 0
            ? productDescription[0].description.data.description
            : "",
          sharedImage:
            productImages?.length > 0
              ? productImages[0]?.formats?.small?.url
              : "",
          keywords: keywords || name,
        }}
      />
      <BCContainer>
        <ContainerStyle>
          <Breadcrumb
            location={isBrowser && window.location}
            crumbLabel={name}
            crumbSeparator="/"
          />
        </ContainerStyle>
      </BCContainer>
      <ProductStyle>
        <GridProductStyle>
          <div className="slider-wrapper">
            <div className="slides-wrapper">
              {!productImages ? (
                <StaticImage
                  width={890}
                  height={500}
                  src="../assets/static-images/product-placeholder.jpg"
                  alt="placeholder"
                ></StaticImage>
              ) : (
                <Swiper
                  direction="horizontal"
                  modules={[Zoom, Thumbs]}
                  thumbs={{ swiper: thumbsSwiper }}
                  spaceBetween={10}
                  watchSlidesProgress
                  grabCursor
                  centeredSlides
                  zoom
                  loop
                  slidesPerView={1}
                >
                  {productImages.map(
                    ({ alternativeText, localFile, id }, i) => {
                      const imageGetter = getImage(localFile);

                      return (
                        <SwiperSlide key={`${i}${id}`}>
                          <GatsbyImage
                            image={imageGetter}
                            alt={alternativeText}
                          />
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              )}
            </div>
            <div className="thumbs-wrapper">
              {!productImages ? (
                <StaticImage
                  width={150}
                  height={140}
                  src="../assets/static-images/product-placeholder.jpg"
                  alt="placeholder"
                ></StaticImage>
              ) : (
                <Swiper
                  ref={swiperRef}
                  direction="horizontal"
                  freeMode
                  loop={
                    !productImages
                      ? false
                      : productImages.length > SLIDES_PER_VIEW_THUMBS && true
                  }
                  spaceBetween={10}
                  modules={[Navigation, Thumbs]}
                  navigation={{
                    nextEl: "custom-swiper-next-nav",
                    prevEl: "custom-swiper-prev-nav",
                  }}
                  watchSlidesProgress
                  onSwiper={setThumbsSwiper}
                  slidesPerView={SLIDES_PER_VIEW_THUMBS}
                >
                  {productImages.map(
                    ({ alternativeText, localFile, id }, i) => {
                      const imageGetter = getImage(localFile);
                      return (
                        <SwiperSlide key={`${id}${i}`}>
                          <GatsbyImage
                            image={imageGetter}
                            alt={alternativeText}
                          />
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              )}

              {!productImages &&
                productImages?.length > SLIDES_PER_VIEW_THUMBS && (
                  <NavigateSlider reference={swiperRef} />
                )}
            </div>
          </div>
          <ProductDetails>
            <h1>{name}</h1>
            {category?.slug && (
              <ProductBreadcrumbs
                category={{
                  slug: category?.slug,
                  name: category?.name,
                  parent: category?.strapi_parent,
                }}
              />
            )}
            {productDescription?.length > 0 && (
              <ul className="product-description">
                {productDescription.map(({ title, description, id }) => {
                  return (
                    <div key={id}>
                      <li>
                        <h2>{title}</h2>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: description.data.childMarkdownRemark.html,
                          }}
                        ></div>
                      </li>
                      <div className="product-divider"></div>
                    </div>
                  );
                })}
              </ul>
            )}
            {product_licenses?.length > 0 && (
              <ul className="product-license">
                <li>
                  <h2>Licenses</h2>
                  <ul className="info-list">
                    {product_licenses?.map(({ id, name }) => {
                      return (
                        <li key={id} className="info">
                          {name}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            )}
            {product_sizes?.length > 0 && (
              <>
                <div className="product-divider"></div>
                <div className="other-sizes">
                  <h2>Sizes</h2>
                  <ul>
                    {product_sizes
                      .sort((a, b) => a.order - b.order)
                      .map(({ size, id }) => (
                        <li key={id}>{size}</li>
                      ))}
                  </ul>
                </div>
              </>
            )}
            {notice?.data?.childMarkdownRemark?.html && (
              <div
                className="notice-message"
                dangerouslySetInnerHTML={{
                  __html: notice?.data?.childMarkdownRemark?.html,
                }}
              ></div>
            )}
          </ProductDetails>
        </GridProductStyle>
        {productImages && products.length > 0 && (
          <RelatedProducts>
            <div className="heading">
              <h2>
                RELATED <span className="highlight">PRODUCTS</span>
              </h2>
            </div>
            <Swiper
              ref={secondSwiperRef}
              direction="horizontal"
              spaceBetween={10}
              slidesPerView={6}
              modules={[Navigation]}
              navigation
              breakpoints={{
                1300: {
                  slidesPerView: 5,
                },
                1100: {
                  slidesPerView: 4,
                },
                800: {
                  slidesPerView: 3,
                },
                500: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
            >
              {products.map(relatedProduct => {
                const imageGetter = getImage(
                  relatedProduct.productImages[0]?.localFile
                );
                return (
                  <SwiperSlide key={relatedProduct.id}>
                    <Link to={`/product/${relatedProduct.slug}`}>
                      <div className="slide-content">
                        <GatsbyImage
                          image={imageGetter}
                          alt={relatedProduct.productImages[0]?.alternativeText}
                        />
                        <h4>{relatedProduct.name}</h4>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </RelatedProducts>
        )}
      </ProductStyle>
    </>
  );
}

export const singleProductQuery = graphql`
  query singleProduct($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      name
      category {
        name
        slug
        strapi_parent {
          name
          slug
        }
      }
      productDescription {
        title
        id
        description {
          data {
            description
            childMarkdownRemark {
              html
            }
          }
        }
      }
      productImages {
        id
        alternativeText
        formats {
          small {
            url
          }
        }
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: AUTO
              blurredOptions: { toFormat: AUTO }
              layout: FULL_WIDTH
            )
          }
        }
      }
      notice {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      products {
        id
        slug
        name
        productImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: AUTO
              )
            }
          }
        }
      }
      product_licenses {
        name
        id
      }
      product_sizes {
        size
        id
        order
        strapi_id
      }
      metaTitle
      metaDescription
      keywords
    }
  }
`;
