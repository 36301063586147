import React from "react";
import { ArrowDownIcon, ArrowUpIcon } from "./CustomIcons";

export default function NavigateSlider({ reference: { current } }) {
  return (
    <div>
      <button
        className="custom-swiper-next-nav"
        onClick={() => current.swiper.slideNext()}
      >
        <ArrowUpIcon />
      </button>
      <button
        className="custom-swiper-prev-nav"
        onClick={() => current.swiper.slidePrev()}
      >
        <ArrowDownIcon />
      </button>
    </div>
  );
}
