import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const ProductBreadcrumbsStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  .divider {
    width: 1em;
    height: 2px;
    background-color: ${({ theme: { palette } }) => palette.primary[500]};
    border-radius: 10px;
  }
  a {
    color: ${({ theme: { palette } }) => palette.primary[500]};
    text-transform: capitalize;
    font-size: 0.9rem;
  }
`;

export default function ProductBreadcrumbs({ category }) {
  return (
    <ProductBreadcrumbsStyle>
      <Link to={category.slug}>{category.name}</Link>
    </ProductBreadcrumbsStyle>
  );
}
